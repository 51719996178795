import { useCallback, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  IPerformanceReportPayload,
  useGetPerformanceReportsQuery,
  useUpdatePerformanceReportsQueryData,
  useUpdateStatusInReportIndexMutation
} from '../../../store/datahub'
import { useRdot360AccountContext } from '../../../store/rdot360Context'
import { IPerformanceReport } from '../../../store/types'
import { usePerformanceReportsUiState } from './reportsUiState'

const performanceReportsQuerySelect = [
  'accountList',
  'clientName',
  'fileLocation',
  'id',
  'fileId',
  'portfolioId',
  'portfolioName',
  'reportCategory',
  'reportDate',
  'reportId',
  'reportName',
  'sharedIndicator'
]

const performanceReportPayload: IPerformanceReportPayload = {
  queryType: 'full',
  count: true,
  top: 1000,
  select: performanceReportsQuerySelect.join(',')
}

export const usePerformanceReportsData = () => {
  const { dateRangeString, category = 'ALL' } = usePerformanceReportsUiState()
  const { selectedAccountKeys } = useRdot360AccountContext()
  const selectedAccountKeysJoined = selectedAccountKeys?.join('|')
  const fromToDates = dateRangeString ? dateRangeString.split(',') : []
  const [startDate, endDate] = fromToDates
  const startDateWithISOTime = startDate && `${startDate}T00:00:00Z`
  const endDateWithISOTime = endDate && `${endDate}T23:59:59Z`
  const isDatesSet = startDateWithISOTime && endDateWithISOTime
  const filterItems = [
    `accountKey/any(accountKey: search.in(accountKey, '${selectedAccountKeysJoined}', '|'))`,
    isDatesSet
      ? `reportDate ge ${startDateWithISOTime} and reportDate le ${endDateWithISOTime}`
      : null,
    category && category !== 'ALL' ? `reportCategory eq '${category}'` : null
  ]
  const filter = filterItems.filter(isNotNullOrUndefined).join(' and ')
  const performanceReportPayloadWithFilter = useMemo(
    () => ({
      ...performanceReportPayload,
      filter
    }),
    [filter]
  )
  const {
    data,
    error,
    isLoading: isFetching,
    isUninitialized
  } = useGetPerformanceReportsQuery(performanceReportPayloadWithFilter)

  const updatePerformanceReportsQueryData =
    useUpdatePerformanceReportsQueryData()

  const [updateStatusInReportIndex] = useUpdateStatusInReportIndexMutation()

  const updateReportStatus = useCallback(
    async (fileId?: string) => {
      updatePerformanceReportsQueryData(
        performanceReportPayloadWithFilter,
        fileId
      )
      updateStatusInReportIndex({ id: fileId, sharedIndicator: 'Yes' })
    },
    [
      performanceReportPayloadWithFilter,
      updatePerformanceReportsQueryData,
      updateStatusInReportIndex
    ]
  )

  const dataOnlyWhenSuccessful = useMemo(() => {
    const empty: IPerformanceReport[] = []
    return error ? empty : data || empty
  }, [data, error])

  return {
    data: dataOnlyWhenSuccessful,
    isFetching,
    isUninitialized,
    updateReportStatus
  }
}
